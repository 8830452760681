import Card from 'react-bootstrap/Card';
import TermsButton from './termsAndConditionsPopup';

import { Separator } from '../story_review/storyReview';
import interfaceTextMap from '../../utils/interfaceText';

function InitScreen(props) {

  const langCode = props.langCode;

  const interfaceText = interfaceTextMap[langCode]["init_screen"];

  return (
    <>
      <Card.Title>
          <h2>{interfaceText["header"]}</h2>
      </Card.Title>
      <Card.Text>
          {interfaceText["welcome_text"]}
      </Card.Text>
      <Card.Title className="mt-3">
          <Separator/> {interfaceText["keyword_title"]}
      </Card.Title>
      <Card.Text>
          {interfaceText["keyword_desc"]}
      </Card.Text>

      <Card.Title className="mt-3">
          <Separator/> {interfaceText["sentences_title"]}
      </Card.Title>
      <Card.Text>
          {interfaceText["sentences_desc"]}
      </Card.Text>

      <Card.Title className="mt-3">
          <Separator/> {interfaceText["title_title"]}
      </Card.Title>
      <Card.Text>
          {interfaceText["title_desc"]}
      </Card.Text>

      <Card.Title className="mt-3">
          <Separator/> {interfaceText["share_title"]}
      </Card.Title>
      <Card.Text>
          {interfaceText["share_desc"]}
      </Card.Text>

      <Card.Text className="mt-3">
          {interfaceText["t_and_c_text"]}<TermsButton/>
      </Card.Text>
      <Card.Text>
          {interfaceText["cheer_msg"]}
      </Card.Text>
    </>
  );
}

export default InitScreen;
