import { API } from 'aws-amplify';

function warmUpAi(langCode){

  const post_url_suffix = langCode === "en" ? "" : "/" + langCode;
  API.get('tyypewriterRestApi', '/suggestions/warmup' + post_url_suffix).catch(error => {
    console.error("Error sending AI report");
    console.error(error.response);
  })
}

function sendAiReportDetails(reportType, reportDetails, reportCurrentState, langCode){
  reportCurrentState["langCode"] = langCode;
  
  const reportDict = {
    "reportType": reportType,
    "reportDetails": reportDetails,
    "reportCurrentState": reportCurrentState,
  };

  const payload = {
      body: reportDict, // replace this with attributes you need
  };

  console.log("Sending AI report");
  console.log(payload);

  const post_url_suffix = langCode === "en" ? "" : "/" + langCode;
  API.post('tyypewriterRestApi', '/report/ai' + post_url_suffix, payload).then(response => {
    console.log("Sent AI report");
    console.log(response);
  })
  .catch(error => {
    console.error("Error sending AI report");
    console.error(error.response);
  })
}

function sendCompletedStory(currentState, langCode){
  currentState["langCode"] = langCode;

  const payload = {
      body: currentState, // replace this with attributes you need
  };

  const post_url_suffix = langCode === "en" ? "" : "/" + langCode;
  API.post('tyypewriterRestApi', '/save/finished' + post_url_suffix, payload).then(response => {
    console.log("Story send complete");
    console.log(response);
  })
  .catch(error => {
    console.log("Story send error");
    console.log(error.response);
  })
}

function sendNominatedStory(currentState, langCode){
  currentState["langCode"] = langCode;

  const payload = {
      body: currentState, // replace this with attributes you need
  };

  const post_url_suffix = langCode === "en" ? "" : "/" + langCode;
  API.post('tyypewriterRestApi', '/save/nominate' + post_url_suffix, payload).then(response => {
    console.log("Story nominate complete");
    console.log(response);
  })
  .catch(error => {
    console.log("Story nominate error");
    console.log(error.response);
  })
}

export { warmUpAi, sendAiReportDetails, sendCompletedStory, sendNominatedStory };
