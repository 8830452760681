import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';

function AboutPage(props) {
  return (
    <Container className="bg-dark text-light p-3 mb-5">
      <Col xs={{span:12}} className={props.className}>
        <Card bg="dark">
          <Card.Title>
              <h2>About the creator</h2>
          </Card.Title>
          <Card.Body>

            <Row>
              <Col xs={12} md={6}>
                <Card.Text>
                tyypewriter was created by Peter Devine, a PhD student at the <Nav.Link className="d-inline ms-0 ps-0 link-warning" href="https://hasel.auckland.ac.nz/">HASEL Lab in The University of Auckland.</Nav.Link>
                </Card.Text>

                <Card.Text>
                    My main research concerns analysing software user feedback to help improve software quality.
                </Card.Text>

                <Card.Text>
                    I love natural language processing (NLP), and the possibilities that combining AI with language can create.
                </Card.Text>

                <Card.Text>
                    I also love movies and their ability to take you anywhere through a screen.
                </Card.Text>

                <Card.Text>
                    tyypewriter is a natural extension of these two interests. I hope you get something out of using it!
                </Card.Text>
              </Col>
              <Col xs={12} md={6}>
                <Image className="my-3" src="https://hasel.auckland.ac.nz/files/2019/10/lab_photo-e1599857103184.jpg" rounded />
              </Col>
              </Row>
            <Card.Text>
                I will also (hopefully) be graduating roughly at the start of 2023. I am looking for a role working with NLP, and am happy to work anywhere in the world as long as the role is interesting.
            </Card.Text>
            <Card.Text>
                If you would like to contact me regarding jobs, please feel free to reach out at tyypewriter (at symbol) gmail.com or connect with me on <Nav.Link className="d-inline ms-0 ps-0 link-warning" href="https://www.linkedin.com/in/peter-devine-030b2012a">LinkedIn.</Nav.Link>
            </Card.Text>
        </Card.Body>
        </Card>
        <Card bg="dark" className="mt-5">
          <Card.Title>
              <h2>About tyypewriter</h2>
          </Card.Title>
          <Card.Body>
            <Card.Text>
                My vision for tyypewriter is that it can introduce collaboratively working with an AI to create something entertaining.
            </Card.Text>
            <Card.Text>
                One big inspiration for this work is AI Dungeon (https://play.aidungeon.io/main/home), which serves you interesting scenarios that you have to navigate.
            </Card.Text>
            <Card.Text>
                I like the idea of working together with an AI to create a work of art that you can then share with others.
            </Card.Text>
            <Card.Text>
                I can imagine a future where eventually movies, TV shows, songs and other media are all create couture for your tastes using AI. This is an attempt at a basic level of this.
            </Card.Text>
            <Card.Text>
                If I had unlimited money and time, I would like to add the following to this site:
                <ListGroup as="ol" numbered  className="mt-3">
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Faster and more coherent AI</div>
                      tyypewriter is currently hosted on a CPU. I'd like to host it on a GPU to speed up inference and/or potentially improve the quality of text generation (i.e. make it make sense while keeping it interesting) by spending more time generating each sentence.
                    </div>
                  </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Better UI</div>
                        Ideally, I'd like a UI where you can login so that you can create your own stories and vote on others' stories freely.
                      </div>
                    </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                    <div className="fw-bold">More languages</div>
                    I'd like to support other languages for the story generation (currently only English is supported).
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                    <div className="fw-bold">Longer stories</div>
                    Making longer, more susbstantial stories would make for a more engrossing AI, in my opinion.
                    However, technical-cost limitations prevent this at present.
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                    <div className="fw-bold">New formats of text</div>
                    Similar to above, beyond short story synopses, I'd like to add support for writing scenes of dialogue, poems, song lyrics and other forms of text.
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                    <div className="fw-bold">Beyond text</div>
                    Again, due to technical limitations, tyypewriter is a purely text based AI. In the far future, I'd love to see an AI that can take a story synopsis and dialogue script, and then make a video of that scene. That would be cool.
                    </div>
                  </ListGroup.Item>
                </ListGroup>
            </Card.Text>
          </Card.Body>
          </Card>
      </Col>

    </Container>
  );
}

export default AboutPage;
