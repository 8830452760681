import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import TagInput from './story_writer/tagInput';
import SentenceOrTitleInput from './story_writer/sentenceOrTitleInput';
import InitScreen from './story_writer/initScreen';
import { ReviewScreen } from './story_review/storyReview';
import ForwardBackButtonHolder from './story_writer/forwardBackButtonHolder';
import ReportButton from './story_writer/reportButton';

import { useState } from 'react';

import {InputStages, InputStagesOrder} from '../utils/writingInputStages';
import { getSentenceChoicesFromAPI, getTitleChoicesFromAPI } from '../utils/dataGetUtils';
import { warmUpAi, sendAiReportDetails, sendCompletedStory } from '../utils/dataSendUtils';
import {MAX_SENTENCES, MAX_TITLES} from '../utils/maxTexts';
import interfaceTextMap from '../utils/interfaceText';

function StoryCreatorPage(props) {

  const [isWarmedUp, setIsWarmedUp] = useState(false);

  const langCode = props.langCode;
  const interfaceText = interfaceTextMap[langCode]["story_creator_page"];

  if(!isWarmedUp){
    warmUpAi(langCode);
    setIsWarmedUp(true);
  }

  const [tagList, setTagList] = useState([]);

  const [sentenceList, setSentenceList] = useState([]);
  const [sentenceWasAiGenList, setSentenceWasAiGenList] = useState([]);
  const [sentenceChoices, setSentenceChoices] = useState([]);

  const [titleList, setTitleList] = useState([]);
  const [titleWasAiGenList, setTitleWasAiGenList] = useState([]);
  const [titleChoices, setTitleChoices] = useState([]);

  const [activeState, setActiveState] = useState(InputStages.INIT);

  function saveCurrentState(){
    return(
      {
        "tagList": [...tagList],
        "sentenceList": [...sentenceList],
        "sentenceWasAiGenList": [...sentenceWasAiGenList],
        "sentenceChoices": [...sentenceChoices],
        "titleList": [...titleList],
        "titleWasAiGenList": [...titleWasAiGenList],
        "titleChoices": [...titleChoices],
        "activeState": activeState
  })}

  function getNewSentenceChoices(changeItem, newSentenceList, newSentenceWasAiGenList, changeType){
      const currentState = saveCurrentState();
      currentState["changedSentence"] = changeItem;
      currentState["newSentenceList"] = newSentenceList;
      currentState["newSentenceWasAiGenList"] = newSentenceWasAiGenList;
      currentState["changeType"] = changeType;

      getSentenceChoicesFromAPI(currentState, setSentenceChoices, langCode);
  }

  function getNewTitleChoices(changeItem, newTitleList, newTitleWasAiGenList, changeType){
      const currentState = saveCurrentState();
      currentState["changedSentence"] = changeItem;
      currentState["newTitleList"] = newTitleList;
      currentState["newTitleWasAiGenList"] = newTitleWasAiGenList;
      currentState["changeType"] = changeType;

      getTitleChoicesFromAPI(currentState, setTitleChoices, langCode);
  }

  function sendFinalStory(changeType){
      const currentState = saveCurrentState();
      currentState["changeType"] = changeType;

      sendCompletedStory(currentState, langCode);
  }

  function canGoNext(){

    if (activeState === InputStages.INIT){
      return true
    }else if(activeState === InputStages.TAGS){
      return tagList.length > 0;
    }else if(activeState === InputStages.SYNOPSIS){
      return sentenceList.length > 0;
    }else if(activeState === InputStages.TITLE){
      return titleList.length > 0;
    }else{
      return true
    }
  }

  function hasBackButton(){
    if (activeState === InputStages.INIT){
      return false
    } else{
      return true
    }
  }

  function hasNextButton(){
    if (activeState === InputStages.REVIEW){
      return false
    } else{
      return true
    }
  }

  function backStageFn(){
    // Reset all data
    setTagList([])
    setSentenceList([])
    setSentenceWasAiGenList([])
    setSentenceChoices([])
    setTitleList([])
    setTitleWasAiGenList([])
    setTitleChoices([])

    // if (activeState === InputStages.TITLE){
    //   getNewSentenceChoices(activeState)
    // }
    // const stageIdx = InputStagesOrder.indexOf(activeState);
    // setActiveState(InputStagesOrder[stageIdx - 1]);
    setActiveState(InputStages.INIT);
  }

  function nextStageFn(){
    if ((activeState === InputStages.TAGS) && (sentenceList.length < MAX_SENTENCES)){
      getNewSentenceChoices("", sentenceList, sentenceWasAiGenList, "forwardToSentence")
    } else if((activeState === InputStages.SYNOPSIS) && (titleList.length < MAX_TITLES)){
      getNewTitleChoices("", titleList, titleWasAiGenList, "forwardToTitle")
    } else if(activeState === InputStages.TITLE){
      sendFinalStory("finalizedStory")
    }

    const stageIdx = InputStagesOrder.indexOf(activeState);
    setActiveState(InputStagesOrder[stageIdx + 1]);
  }

  function nextButtonText(){
    if(activeState === InputStages.INIT){
        return interfaceText["start_button"]
    }else if(activeState === InputStages.SYNOPSIS){
        return interfaceText["add_title"]
    }else if(activeState === InputStages.TITLE){
        return interfaceText["finish_button"]
    }else{
        return interfaceText["next_button"]
    }
  }

  function backButtonText(){
    if(activeState === InputStages.REVIEW){
        return interfaceText["start_again"]
    }else{
        return interfaceText["reset_button"]
    }
  }

  function currentScreenElement(){
    if (activeState === InputStages.INIT){
      return <InitScreen langCode={langCode} className=""/>
    }else if(activeState === InputStages.TAGS){
      return <TagInput langCode={langCode} textList={tagList} setTagList={setTagList}/>
    }else if(activeState === InputStages.SYNOPSIS){
      return <SentenceOrTitleInput
                            langCode={langCode}
                            currentStage={activeState}
                            stepTitle={interfaceText["sentence_step_name"]}
                            dataNameSingular={interfaceText["sentence_sing"]}
                            textList={sentenceList}
                            textChoices={sentenceChoices}
                            setTextList={setSentenceList}
                            textWasAiGenList={sentenceWasAiGenList}
                            setTextWasAiGenList={setSentenceWasAiGenList}
                            getNewTextChoices={getNewSentenceChoices}/>
    }else if(activeState === InputStages.TITLE){
      return <SentenceOrTitleInput
                            langCode={langCode}
                            currentStage={activeState}
                            stepTitle={interfaceText["title_step_name"]}
                            dataNameSingular={interfaceText["title_sing"]}
                            textList={titleList}
                            textChoices={titleChoices}
                            setTextList={setTitleList}
                            textWasAiGenList={titleWasAiGenList}
                            setTextWasAiGenList={setTitleWasAiGenList}
                            getNewTextChoices={getNewTitleChoices}
                            secondaryTextList={sentenceList}/>
    }else if(activeState === InputStages.REVIEW){
      return <ReviewScreen
                            langCode={langCode}
                            title={titleList[0]}
                            tags={tagList}
                            synopsis={sentenceList}
                            synopsisWasAiGen={sentenceWasAiGenList}
                            titleWasAiGen={titleWasAiGenList[0]}
                            storyId={null}
                            isPrepublished={true}/>
    }else{
      return ""
    }
  }

  const backButton = <Button className="w-100 my-4" variant="secondary" onClick={backStageFn}>{backButtonText()}</Button>;
  const nextButton = <Button className="w-100 my-4" variant="warning" disabled={!canGoNext()} onClick={nextStageFn}><b>{nextButtonText()}</b></Button>;

  function sendReport(reportType, reportDetails){
      console.log("REPORT SEND FN!")
      const reportCurrentState = saveCurrentState();
      sendAiReportDetails(reportType, reportDetails, reportCurrentState, langCode);
  }

  const storyWritingElement =   <>
          <Row>
            {currentScreenElement()}
          </Row>
          <ForwardBackButtonHolder backButton={hasBackButton() ? backButton : ""} forwardButton={hasNextButton() ? nextButton : ""}/>

          </>;

  return (
    <>
      <Container className="bg-dark text-light p-3  mb-5">

        <Col xs={{span:12}} className={props.className}>
          <Card bg="dark">
            <Card.Body>
                {storyWritingElement}
            </Card.Body>
          </Card>
        </Col>
      </Container>

      {hasBackButton() ? <ReportButton sendReportDetails={sendReport} className="mt-5"/> : ""}
    </>
  );
}

export default StoryCreatorPage;
