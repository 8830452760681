import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ForwardBackButtonHolder from './story_writer/forwardBackButtonHolder';

import { ReviewScreen } from './story_review/storyReview';

import { TwitterTweetEmbed } from 'react-twitter-embed';


import { useState } from 'react';

function SingleReadStory(props){
  return(
    <Card className="my-4 mx-lg-5 p-4 pb-0" bg="dark" border={props.isWinner ? "warning" : "white"}>
      <ReviewScreen
          title="Something cool"
          tags={["Something", "Something else"]}
          synopsis={["Bunch of sentences.", "all in a row.", "Nice and neat."]}
          storyId={null}
          isPrepublished={false}/>
    </Card>
  )
}

function StoriesHeader(props){
  return(
    <Card className="my-4 mx-lg-5 p-4 pb-0"  bg="dark">
      <h2>{props.children}</h2>
    </Card>
  )
}

// function StoriesHeader(props){
//   return(
//     <Card className="my-4 mx-lg-5 p-4 pb-0"  bg="dark">
//       <h1>{props.children}</h1>
//     </Card>
//   )
// }

function ReadPage(props) {

  const backButton = <Button block>Previous</Button>;
  const forwardButton = <Button block>Next</Button>;

  return (
    <Container className="bg-dark text-light p-3  mb-5">
      <h1 className="my-4 mx-lg-5 p-4 pb-0">Awards for 1st December 2000</h1>
      <ForwardBackButtonHolder className="my-4 mx-lg-5 p-4 pb-0" backButton={backButton} forwardButton={forwardButton}/>

      <StoriesHeader>Vote on Twitter</StoriesHeader>
      <Row className="my-4 mx-lg-5 p-4 pb-0">
          <Col xs={{span: 0}} md={{span: 1}} lg={{span: 2}} xl={{span: 3}}>
          </Col>
          <Col xs={{span: 12}} md={{span: 10}} lg={{span: 8}} xl={{span: 6}}>
              <TwitterTweetEmbed
                 tweetId={'1509276979828244481'}/>
          </Col>
          <Col xs={{span: 0}} md={{span: 1}} lg={{span: 2}} xl={{span: 3}}>
          </Col>
      </Row>
      <StoriesHeader>Winner:</StoriesHeader>
      <SingleReadStory isWinner={true}/>
      <StoriesHeader>Nominees:</StoriesHeader>
      <SingleReadStory isWinner={false}/>
      <SingleReadStory isWinner={false}/>
      <SingleReadStory isWinner={false}/>
      <span  className="my-4 mx-lg-5 p-4 pb-0">Congratulations all!</span>
    </Container>
  );
}

export default ReadPage;
