// import InputGroup from 'react-bootstrap/InputGroup';

// import { useState } from 'react';

import Container from 'react-bootstrap/Container';
import CloseButton from 'react-bootstrap/CloseButton';


function TitleDisplay(props) {


  const titleElement = props.titleText.length > 0 ? <Container className="my-3 text-center">
    <h1>
        {props.titleText[0]}
        <CloseButton className="close-button ms-2" variant="white" onClick={props.removeTitle}/>
    </h1>
  </Container> : <></>;

  return (
    titleElement
  )
}

export default TitleDisplay;
