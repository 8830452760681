const interfaceTextMap = {
  "en": {
      "start_page": {
          "title": "Welcome to tyypewriter",
          "description": "The AI that helps you write stories.",
          "start_writing_button": "Start writing",
      },
      "init_screen": {
          "header": "Write something new!",
          "welcome_text": "Welcome to tyypewriter, where you can write a fun story with your AI co-writer.",
          "keyword_title": "Step 1: Keywords",
          "keyword_desc": "Choose some keywords to describe your story.",
          "sentences_title": "Step 2: Story sentences",
          "sentences_desc": "Write your story sentence by sentence, either by choosing one of the four AI generated suggestions or by writing your own.",
          "title_title": "Step 3: Title",
          "title_desc": "Title your story either using AI suggestions or your own title.",
          "share_title": "Step 4: Share",
          "share_desc": "You can share your story on Twitter and Reddit (r/tyypewriter).",
          "t_and_c_text": "By using this app, you agree to the",
          "cheer_msg": "Have fun!",
      },
      "nav_bar": {
          "product_name": "tyypewriter",
          "write_button_title": "Write",
          "read_button_title": "Read",
          "about_button_title": "About",
          "contact_button_title": "Contact",
          "alt_lang_button_title": "日本語",
          "alt_lang_url": "/ja",
      },
      "tag_screen": {
          "step_title": "Step 1: Keywords",
          "step_descriptions": ["Describe the setting, characters or plot of the story (Max. 5 tags).", "For best results, we recommend using all 5 tags to describe your story.", "(E.g. AI, story writing, robot friendship, author, best seller)"],
          "tag_input_name": "key phrase",
      },
      "type_input": {
          "type_your_text": (dataType) => "Type your ".concat(dataType),
          "add_button_text": "Add",
      },
      "text_options_list": {
          "please_wait_texts": ["Please wait - getting AI response", "This can take up to 30 seconds", "Hum your favorite song until it loads"],
      },
      "data_choice_input": {
          "typing_intro_text": "Or write your own:",
      },
      "sentence_or_title_input": {
          "choose_sugg_text": (dataNameSingular) => `Choose a ${dataNameSingular} from the list of suggestions:`,
      },
      "review_page": {
          "nominate": "Nominate for top story",
          "nominated": "Nominated!",
      },
      "reddit_share": {
          "share_text": "Share story on Reddit",
      },
      "twitter_share": {
          "share_text": "Share story on Twitter",
      },
      "story_creator_page": {
          "start_button": "Start",
          "add_title": "Add title",
          "finish_button": "Finish",
          "next_button": "Next",
          "start_again": "Start again",
          "reset_button": "Reset",
          "sentence_step_name": "Step 2: Story sentences",
          "sentence_sing": "sentence",
          "title_step_name": "Step 3: Title",
          "title_sing": "title",
      },
      "sentence_api_messages": {
          "error_message": "Sorry, the sentence AI has broken, please enter your own sentence below."
      },
      "title_api_messages": {
          "error_message": "Sorry, the title AI has broken, please enter your own title below."
      },
  },
  "ja": {
      "start_page": {
          "title": "tyypewriterへようこそ",
          "description": "tyypewriterはストーリーを書くのに役立つAIです",
          "start_writing_button": "ストーリーを書き始める",
      },
      "init_screen": {
          "header": "新しいストーリーを書きましょう!",
          "welcome_text": "tyypewriterへようこそ。楽しいストーリーをAIと書くことができます。",
          "keyword_title": "ステップ１： キーワード",
          "keyword_desc": "ストーリー、設定、キャラクター詳細についての言葉を入力",
          "sentences_title": "ステップ２： ストーリーの文章",
          "sentences_desc": "AIの提案文章で、あるいは自分の作成で、一つずつ文章を入力",
          "title_title": "ステップ３： タイトル",
          "title_desc": "タイトルを選択",
          "share_title": "ステップ４： 共有",
          "share_desc": "ツイッター、レディット（r/tyypewriter)でストーリーを共有できます。",
          "t_and_c_text": "By using this app, you agree to the",
          "cheer_msg": "楽しんでください!",
      },
      "nav_bar": {
          "product_name": "tyypewriter",
          "write_button_title": "書く",
          "read_button_title": "読む",
          "about_button_title": "作成者について",
          "contact_button_title": "お問い合せ",
          "alt_lang_button_title": "English",
          "alt_lang_url": "/",
      },
      "tag_screen": {
          "step_title": "ステップ１： キーワード",
          "step_descriptions": ["ストーリー、設定、キャラクター詳細についての言葉を入力", "おすすめ：最大限５つを入力すれば、一番いいストーリーを果たす", "(例：東京、寿司屋、ミステリー、探偵、失踪）"],
          "tag_input_name": "キーワード",
      },
      "type_input": {
          "type_your_text": (dataType) => `${dataType}を入力`,
          "add_button_text": "追加",
      },
      "text_options_list": {
          "please_wait_texts": ["少々を待ちください", "AIの反応は３０秒程かかります", "..."],
      },
      "data_choice_input": {
          "typing_intro_text": "あるいは自分作りのを入力：",
      },
      "sentence_or_title_input": {
          "choose_sugg_text": (dataNameSingular) => `おすすめの${dataNameSingular}の中で１つを選択`,
      },
      "review_page": {
          "nominate": "ノミネート",
          "nominated": "ノミネート済",
      },
      "reddit_share": {
          "share_text": "レディットで共有",
      },
      "twitter_share": {
          "share_text": "ツイッターで共有",
      },
      "story_creator_page": {
          "start_button": "開始",
          "add_title": "タイトルを追加",
          "finish_button": "完了",
          "next_button": "次",
          "start_again": "再開",
          "reset_button": "リセット",
          "sentence_step_name": "ステップ２： ストーリーの文章",
          "sentence_sing": "文章",
          "title_step_name": "ステップ３： タイトル",
          "title_sing": "タイトル",
      },
      "sentence_api_messages": {
          "error_message": "大変申し訳ございませんが、文章作成AIからエラーが発生しました。自分で次の文章を以下にご入力ください。"
      },
      "title_api_messages": {
          "error_message": "大変申し訳ございませんが、タイトル作成AIからエラーが発生しました。自分でタイトルを以下にご入力ください。"
      },
  },
};

export default interfaceTextMap;
