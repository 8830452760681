import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';

import { SocialIcon } from 'react-social-icons';

function ContactPage(props) {
  return (
    <Container className="bg-dark text-light p-3 mb-5">
      <Col xs={{span:12}} className={props.className}>
        <Card bg="dark">
          <Card.Title>
              <h2>Connect with tyypewriter</h2>
          </Card.Title>
          <Card.Body>
            <Card.Text>
                Reach out to tyypewriter through one of our channels!
            </Card.Text>
            <Card.Text>
                Email - tyypewriter (at symbol) gmail.com
            </Card.Text>
            <Card.Text>
                <SocialIcon url="https://twitter.com/tyypewriter" fgColor="#ffffff"/> Twitter -<Nav.Link className="d-inline ms-0 link-warning" href="https://twitter.com/tyypewriter">@tyypewriter</Nav.Link>
            </Card.Text>
            <Card.Text>
                <SocialIcon url="https://www.instagram.com/tyypewriterstories" fgColor="#ffffff"/> Instagram -<Nav.Link className="d-inline ms-0 link-warning" href="https://www.instagram.com/tyypewriterstories">@tyypewriterstories</Nav.Link>
            </Card.Text>
            <Card.Text>
                <SocialIcon url="https://www.reddit.com/r/tyypewriter" fgColor="#ffffff"/> Reddit -<Nav.Link className="d-inline ms-0 link-warning" href="https://www.reddit.com/user/tyypewriter">u/tyypewriter</Nav.Link> or our subreddit <Nav.Link className="d-inline ms-0 link-warning" href="https://www.reddit.com/r/tyypewriter">r/tyypewriter</Nav.Link>
            </Card.Text>
        </Card.Body>
        </Card>
      </Col>

    </Container>
  );
}

export default ContactPage;
