import Container from 'react-bootstrap/Container';

import AppNavBar from './elements/navBar';
import StartPage from './elements/startPage';
import StoryCreatorPage from './elements/storyCreatorPage';
import ReadPage from './elements/readPage';
import AboutPage from './elements/aboutPage';
import ContactPage from './elements/contactPage';

import { Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';

function App() {
  const enNavBar = <AppNavBar langCode="en" className="py-0 my-3"/>;
  const jaNavBar = <AppNavBar langCode="ja" className="py-0 my-3"/>;

  const enStartPage = <>{enNavBar}<StartPage langCode="en"/></>;
  const jaStartPage = <>{jaNavBar}<StartPage langCode="ja"/></>;

  const enStoryCreatorPage = <>{enNavBar}<StoryCreatorPage langCode="en"/></>;
  const jaStoryCreatorPage = <>{jaNavBar}<StoryCreatorPage langCode="ja"/></>;

  const enReadPage = <>{enNavBar}<ReadPage langCode="en"/></>;
  const jaReadPage = <>{jaNavBar}<ReadPage langCode="ja"/></>;

  const enAboutPage = <>{enNavBar}<AboutPage langCode="en"/></>;
  const jaAboutPage = <>{jaNavBar}<AboutPage langCode="ja"/></>;

  const enContactPage = <>{enNavBar}<ContactPage langCode="en"/></>;
  const jaContactPage = <>{jaNavBar}<ContactPage langCode="ja"/></>;

  return (
    <Container className="">

      <Routes>
        <Route path="/" element={enStartPage} />
        <Route path="/ja" element={jaStartPage} />

        <Route path="/story" element={enStoryCreatorPage} />
        <Route path="/story/ja" element={jaStoryCreatorPage} />

        <Route path="/about" element={enAboutPage} />
        <Route path="/about/ja" element={jaAboutPage} />

        <Route path="/contact" element={enContactPage} />
        <Route path="/contact/ja" element={jaContactPage} />
      </Routes>
    </Container>
  );
}

export default App;
