import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import RatingDisplay from './ratingDisplay';

import { sendNominatedStory } from '../../utils/dataSendUtils';

import interfaceTextMap from '../../utils/interfaceText';

import {
  TwitterShareButton,
  TwitterIcon,
  RedditIcon,
} from "react-share";

import { useState } from 'react';

function Separator(props){
  return(<span className="text-warning">|</span>)
}

function ViewHeader(props){
  return(
    <h4 className={props.className}><Separator/> {props.children}</h4>
  )
}

function CustomRedditShareButton(props){

  const langCode = props.langCode;
  const interfaceText = interfaceTextMap[langCode]["reddit_share"];

  const titleText = encodeURIComponent("[STORY] " + props.title);
  const selfText = encodeURIComponent("Tags: " + props.tags + "\n\n" + props.synopsisText);

  const shareUrl = "https://www.reddit.com/r/tyypewriter/submit?title=" + titleText + "&selftext=true&text=" + selfText;
  return(
      <a className={props.className + " text-decoration-none d-flex justify-content-center mt-1"} href={shareUrl} target="_blank" rel="noreferrer noopener">
        {interfaceText["share_text"]} <RedditIcon className="ms-2" size={32} borderRadius={20} />
      </a>
  )
}

function CustomTwitterShareButton(props){

  const langCode = props.langCode;
  const interfaceText = interfaceTextMap[langCode]["twitter_share"];

  var twitterShareText = props.title + "\n\n" + props.synopsisText;
  twitterShareText = twitterShareText.match(/.{1,236}/g).join("\n\n");

  return(
    <TwitterShareButton
      className={props.className}
       title={twitterShareText}
       url="Written with tyypewriter.com"
     >{interfaceText["share_text"]}

                 <TwitterIcon className="ms-2" size={32} borderRadius={20} />
   </TwitterShareButton>
  )
}

function ReviewScreen(props) {

    const langCode = props.langCode;
    const interfaceText = interfaceTextMap[langCode]["review_page"];

    const tagEls = props.tags.map((x) => <h4 className="d-inline"><Badge className="m-2" pill text="dark" bg="light">{x}</Badge></h4>)

     // We aren't doing the Title (year) thing because there isn't enough movie related stuff imo.
    const thisYear = new Date().getFullYear();

    const [isNominated, setIsNominated] = useState(false);

    function nominateStory(){
        const nominationData = {
              "tagList": [...props.tags],
              "sentenceList": [...props.synopsis],
              "sentenceWasAiGenList": [...props.synopsisWasAiGen],
              "titleList": [props.title],
              "titleWasAiGenList": [props.titleWasAiGen],
        }

        sendNominatedStory(nominationData);
        setIsNominated(true);
    }

    const synopsisText = props.synopsis.join(" ");

    const isNominatedBtn = isNominated ? <Button variant="outline-warning" className="text-yellow" block disabled>{interfaceText["nominated"]}</Button> : <Button onClick={nominateStory} variant="warning" block><b>{interfaceText["nominate"]}</b></Button>;

    const publishButtonsEl = <><Row className="py-3">

              <Col xs={12} xl={{ span: 4, offset: 4 }} xxl={{ span: 3, offset: 6 }} className="d-grid gap-2 mb-2">
                <CustomTwitterShareButton
                  langCode={langCode}
                  className="text-white"
                  title={props.title}
                  synopsisText={synopsisText}
                />
              </Col>
              <Col xs={12} xl={{ span: 4 }} xxl={{ span: 3}} className="d-grid gap-2 mb-2">
                <CustomRedditShareButton
                  langCode={langCode}
                  className="text-white"
                  title={props.title}
                  tags={props.tags}
                  synopsisText={synopsisText}/>
              </Col>
            </Row>
            </>;

            //  We aren't using the ratings element atm because it is a bit janky (looks crap, doesn't add much to the experience)
            const ratingEls =  <>
                  <Col xs={{span: 12, order: "last"}} lg={4}>
                    <div className="float-lg-end">
                      <Card className="d-inline-flex"><RatingDisplay ratingVal={null} readOnly={false}/></Card>
                    </div>
                  </Col>
                </>;

    return (
      <>
        <Container bg="dark" border="secondary" className="px-md-4 px-0">
          <Row>
            <Col xs={12} lg={12}>
              <h1>{props.title}</h1>
            </Col>
          </Row>
          <Row className="py-3">
            <Col xs={12} sm={12}>
              <ViewHeader>Tags</ViewHeader>
              <Container  className="px-md-4 px-0">{tagEls}</Container>
            </Col>
          </Row>
          <Row className="py-3">
            <Col xs={12} sm={12}>
              <ViewHeader>Synopsis</ViewHeader>
              <Container  className="px-md-4 px-0"><p>{synopsisText}</p></Container>
            </Col>
          </Row>
          {props.isPrepublished ? publishButtonsEl : ""}

        </Container>
      </>
    )
}

export { ReviewScreen, Separator };
