import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { NavLink } from "react-router-dom";

import interfaceTextMap from '../utils/interfaceText';

function AppNavBar(props) {
  const langCode = props.langCode;

  const url_suffix = langCode === "en" ? "" : "/" + langCode;

  const interfaceText = interfaceTextMap[langCode]["nav_bar"];

  return (
      <Navbar className={props.className} bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">{interfaceText["product_name"]}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to={"/story" + url_suffix}>{interfaceText["write_button_title"]}</Nav.Link>
              <Nav.Link as={NavLink} to={"/about" + url_suffix}>{interfaceText["about_button_title"]}</Nav.Link>
              <Nav.Link as={NavLink} to={"/contact" + url_suffix}>{interfaceText["contact_button_title"]}</Nav.Link>
              <Nav.Link as={NavLink} to={interfaceText["alt_lang_url"]}>{interfaceText["alt_lang_button_title"]}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}

export default AppNavBar;
