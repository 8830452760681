import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';


function ReportButton(props) {
  const [show, setShow] = useState(false);

  const [reportType, setReportType] = useState("offensive");
  const [reportDetails, setReportDetails] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function sendReport(){
    props.sendReportDetails(reportType, reportDetails);
    handleClose();
  }

  function reportTypeSelect(event){
    setReportType(event.target.value)
  }

  function reportDetailsInput(event){
    setReportDetails(event.target.value)
  }

  return (
    <>
      <Button className={props.className} variant="outline-danger" size="sm" onClick={handleShow}>
        Report AI
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Report AI</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                Has the AI written something offensive or otherwise inappropriate?
                If so, sorry.
                We will train it to not do this in future.
                <Form.Select onChange={reportTypeSelect} value={reportType}>
                  <option value="offensive">Offensive</option>
                  <option value="nonsense">Nonsensical</option>
                  <option value="other">Other</option>
                </Form.Select>
                Please detail exactly what the model did and we will try and fix it.
                <FormControl
                  value={reportDetails}
                  onChange={reportDetailsInput}
                  placeholder="AI report details"
                />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning" onClick={sendReport}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReportButton;
