// import InputGroup from 'react-bootstrap/InputGroup';

// import { useState } from 'react';

import Container from 'react-bootstrap/Container';

function BackspaceButton(props) {

  return(
    <span className={props.className} onClick={props.removeLastSentence}>
        <a  href="#/" className="link-light">
          <i className="bi bi-backspace-fill align-middle" style={{ fontSize: 30 }}></i>
        </a>
    </span>
  )
}

function SentenceList(props) {

  const sentenceTextList = props.textList.map((s, index) => <span
                                            className={(!props.isDisplayMode) && (index === props.textList.length - 1) ? "highlight me-3" : "faint-highlight me-1"}>
                                              {s}
                                          </span>);

  if ((!props.isDisplayMode) && (props.textList.length > 0)){
    sentenceTextList.push(<BackspaceButton removeLastSentence={props.removeLastSentence} />);
  }

  return (
      <Container className="w-md-75 my-3">{sentenceTextList}</Container>
  )
}

export {SentenceList, BackspaceButton};
