import React from 'react'

import { Rating } from 'react-simple-star-rating'

import { useState } from 'react';

function RatingDisplay(props){

  const initialRating = props.ratingVal === null ? 0 : props.ratingVal;

  const [ratingVal, setRatingVal] = useState(initialRating) // initial rating value

  const handleRatingChange = (rating: number) => {
    setRatingVal(rating)
  }

  return (
      <Rating onClick={handleRatingChange} ratingValue={ratingVal} readonly={props.readOnly} size={25}/>
  )

}

export default RatingDisplay;
