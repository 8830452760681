import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { NavLink } from "react-router-dom";
import interfaceTextMap from '../utils/interfaceText';

function StartPage(props) {
  const langCode = props.langCode;

  const url_suffix = langCode === "en" ? "" : "/" + langCode;

  const interfaceText = interfaceTextMap[langCode]["start_page"];

  return (
    <Container className="bg-dark text-light p-3  mb-5">
      <Col xs={{span:12}} className={props.className}>
        <Card bg="dark">
          <Card.Title>
              <h2>{interfaceText["title"]}</h2>
          </Card.Title>
          <Card.Body>
            <Card.Text>
                {interfaceText["description"]}
            </Card.Text>
            <Card.Text>
              <Row>
                <Col xs={12} md={4}>
                  <NavLink to={"/story" + url_suffix}>
                    <Button variant="warning" size="lg" className="w-100 h-100">
                        {interfaceText["start_writing_button"]}
                    </Button>
                  </NavLink>
                </Col>
                <Col xs={{span:12}}  md={1} className={props.className}>
                  <br/>
                </Col>
              </Row>
            </Card.Text>
        </Card.Body>
        </Card>
      </Col>

    </Container>
  );
}

export default StartPage;
