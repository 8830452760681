import { API } from 'aws-amplify'
import interfaceTextMap from './interfaceText';

function getSentenceChoicesFromAPI(currentState, setSentenceChoicesFn, langCode){
  const payload = {
      body: currentState, // replace this with attributes you need
  };

  setSentenceChoicesFn([]);

  const interfaceText = interfaceTextMap[langCode]["sentence_api_messages"];

  const post_url_suffix = langCode === "en" ? "" : "/" + langCode;
  API.post('tyypewriterRestApi', '/suggestions/sentences' + post_url_suffix, payload).then(response => {
    setSentenceChoicesFn(response);
  })
  .catch(error => {
    console.log(error.response);
    setSentenceChoicesFn([interfaceText["error_message"]]);
  })
}

function getTitleChoicesFromAPI(currentState, setTitleChoicesFn, langCode){
  const payload = {
      body: currentState, // replace this with attributes you need
  };

  setTitleChoicesFn([]);

  const interfaceText = interfaceTextMap[langCode]["sentence_api_messages"];

  const post_url_suffix = langCode === "en" ? "" : "/" + langCode;
  API.post('tyypewriterRestApi', '/suggestions/title' + post_url_suffix, payload).then(response => {
    setTitleChoicesFn(response);
  })
  .catch(error => {
    console.log(error.response);
    setTitleChoicesFn([interfaceText["error_message"]]);
  })
}

export { getSentenceChoicesFromAPI, getTitleChoicesFromAPI };
