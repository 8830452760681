import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ForwardBackButtonHolder(props){
  return(
    <Row className={props.className}>
      <Col className="d-grid" xs={{span:5}} sm={{span:4}} md={{span:3}} lg={{span:2}}>
        {props.backButton}
      </Col>
      <Col xs={{span:2}} sm={{span:4}} md={{span:6}}  lg={{span:8}}>
      </Col>
      <Col className="d-grid" xs={{span:5}} sm={{span:4}} md={{span:3}}  lg={{span:2}}>
        {props.forwardButton}
      </Col>
    </Row>
  )
}

export default ForwardBackButtonHolder;
