import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import { useState } from 'react';


function TermsButton(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Nav.Link className="d-inline" href="#" onClick={handleShow}>T & Cs</Nav.Link>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                The data input by you on this website is collected for the purposes of improving the AI only.
                This website does not (nor do I have any interest in) collecting personally identifiable data such as IP address, device ID etc.
                The creator of this website takes no liability for any damages incurred during its usage.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TermsButton;
