import ListGroup from 'react-bootstrap/ListGroup';
import Placeholder from 'react-bootstrap/Placeholder';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { useState } from 'react';

import interfaceTextMap from '../../utils/interfaceText';

function ListItem(props) {

  const listItemContents = props.isTitle ? <h4>{props.children}</h4> : <>{props.children}</>;
  return (
      <ListGroup.Item action className="d-flex justify-content-center text-center"
                      disabled={props.disabled}
                      onClick={(e) => props.clickFn(e.currentTarget.textContent)}>
                        {listItemContents}
      </ListGroup.Item>
  )
}

function TypeInput(props) {
  const langCode = props.langCode;
  const interfaceText = interfaceTextMap[langCode]["type_input"];

  const [typeInput, setTypeInput] = useState("");
  const MIN_NUM_CHARS_INPUT = 2;
  const MAX_NUM_CHARS_INPUT = props.maxChars;

  function handleTextSubmit(submittedText){
    props.submitTyped(submittedText);
    setTypeInput("");
  }

  const typedButtonSendElement = <Button variant="secondary"
        onClick={(e) => handleTextSubmit(typeInput)}
        disabled={(typeInput.length < MIN_NUM_CHARS_INPUT) || (typeInput.length > MAX_NUM_CHARS_INPUT)}>
        {interfaceText["add_button_text"]}
      </Button>;

  function handleEnter(event){
    if(event.keyCode === 13)
    {
      handleTextSubmit(typeInput)
    }

    if(props.isTagInput && (event.keyCode === 188)){
      //Remove last comma from input tag
      handleTextSubmit(typeInput.slice(0, -1))
    }
  }

  return (
      <InputGroup className="">
        <FormControl
          placeholder={interfaceText["type_your_text"](props.dataType)}
          aria-label={interfaceText["type_your_text"](props.dataType)}
          onChange={(e) => setTypeInput(e.target.value)}
          value={typeInput}
          disabled={props.disabled}
          onKeyUp={(e) => handleEnter(e)}
        />
        {typedButtonSendElement}
      </InputGroup>
  )
}

function TextOptionsList(props) {
  const langCode = props.langCode;
  const interfaceText = interfaceTextMap[langCode]["text_options_list"];

  const textChoiceStringArray = props.textChoices;

  const textListElementArray = [];

  const prependText = props.isTitle ? "" : "> ";

  function selectItem(selectText){
    props.submitChoice(selectText.slice(prependText.length))
  }

  if (textChoiceStringArray.length < 1){
      textListElementArray.push(<ListGroup className="mt-1"><ListItem disabled><Spinner animation="border" /></ListItem></ListGroup>);

      const pleaseWaitTexts = interfaceText["please_wait_texts"];
      for (let i = 0; i < pleaseWaitTexts.length; i++) {
          textListElementArray.push(<ListGroup className="mt-1"><ListItem disabled>{pleaseWaitTexts[i]}</ListItem></ListGroup>);
      }
  } else if (textChoiceStringArray.length === 1){
        textListElementArray.push(<><ListGroup className="mt-1"><ListItem disabled><strong>{textChoiceStringArray[0]}</strong></ListItem></ListGroup></>);
  } else {
    for (let i = 0; i < textChoiceStringArray.length; i++) {
        textListElementArray.push(<><ListGroup className="mt-1"><ListItem isTitle={props.isTitle} clickFn={selectItem}>{prependText + textChoiceStringArray[i]}</ListItem></ListGroup></>);
    }
  }

  return (textListElementArray);
}

function LoadMoreButton(props) {

  return (
    <ListGroup>
      <ListItem isTitle={false} clickFn={props.loadMoreSuggFn}><b>Load more suggestions</b></ListItem>
    </ListGroup>
  );
}

function DataChoiceInput(props) {
    const isGettingChoices = props.textChoices === 0;
    const langCode = props.langCode;

    const interfaceText = interfaceTextMap[langCode]["data_choice_input"];

    return (
      <>
        <Card.Text>
          <TextOptionsList
              langCode={langCode}
              submitChoice={props.submitChoice}
              textChoices={props.textChoices}
              isTitle={props.isTitle}
              />
        </Card.Text>
        <Card.Text>
          {interfaceText["typing_intro_text"]}
        </Card.Text>
        <Card.Text>
            <TypeInput langCode={langCode} dataType={props.dataTypeName} submitTyped={props.submitTyped} maxChars={props.maxChars} disabled={isGettingChoices || props.typeDisabled}/>
        </Card.Text>
      </>
    )
}

export { DataChoiceInput, TypeInput };
