import Badge from 'react-bootstrap/Badge';
import CloseButton from 'react-bootstrap/CloseButton';

function SelectedBadges(props){
  const addedTextElements = props.textList.map((g) => <Badge
                                            className="me-2 mb-1" pill
                                            text="dark"
                                            bg="light">
                                              {g}
                                              <div className="d-inline close-button ms-2"><CloseButton onClick={() => props.removeText(g)}/></div>
                                          </Badge>);
  return (
    <h4>
      {addedTextElements}
    </h4>
  )
}

export default SelectedBadges;
