import Card from 'react-bootstrap/Card';

import TitleDisplay from './titleDisplay';
import { SentenceList } from './sentenceList';
import { DataChoiceInput } from './dataChoiceInput';
import { Separator } from '../story_review/storyReview';

import {InputStages} from '../../utils/writingInputStages';

import {MAX_SENTENCES, MAX_TITLES} from '../../utils/maxTexts';
import interfaceTextMap from '../../utils/interfaceText';

function SentenceOrTitleInput(props) {
  const langCode = props.langCode;
  const interfaceText = interfaceTextMap[langCode]["sentence_or_title_input"];

  const isTitle = props.currentStage === InputStages.TITLE;

  const MAX_TEXT_CHARS = isTitle ? 30 : 90;
  const MAX_TEXTS = isTitle ? MAX_TITLES : MAX_SENTENCES;

  const isMaxInputs = props.textList.length >= MAX_TEXTS;
  const isGettingChoices = props.textChoices.length === 0;

  function removeLastText(){
    const removedText = props.textList.at(-1);
    const isRemovedTextAiGen = props.textWasAiGenList.at(-1);
    const newTexts = props.textList.slice(0, -1);
    const newTextWasAiGen = props.textWasAiGenList.slice(0, -1);
    props.setTextList(newTexts);
    props.setTextWasAiGenList(newTextWasAiGen);

    props.getNewTextChoices(removedText, newTexts, newTextWasAiGen, isRemovedTextAiGen ? "removeAI" : "removeTyped");
  }

  function submitText(addText, choiceType){
    const isAiChoice = choiceType === "choice";

    const newTexts = [...props.textList, addText];
    const newTextWasAiGen = [...props.textWasAiGenList, isAiChoice];
    props.setTextList(newTexts);
    props.setTextWasAiGenList(newTextWasAiGen);

    if(newTexts.length < MAX_TEXTS){
      props.getNewTextChoices(addText, newTexts, newTextWasAiGen, choiceType);
    }
  }

  function submitTextChoice(addText){
    submitText(addText, "choice")
  }

  function submitTextTyped(addText){
    submitText(addText, "type")
  }

  const inputEl = <DataChoiceInput
                        langCode={langCode}
                        dataTypeName={props.dataNameSingular}
                        textChoices={props.textChoices}
                        submitChoice={submitTextChoice}
                        submitTyped={submitTextTyped}
                        maxChars={MAX_TEXT_CHARS}
                        isTitle={isTitle}
                        typeDisabled={isMaxInputs || isGettingChoices}/>;

  const secondaryTextDisplay = isTitle ? props.secondaryTextList : [];

  const displayEl = isTitle ? <><TitleDisplay
                                    titleText={props.textList}
                                    removeTitle={removeLastText}/>
                                    <SentenceList textList={secondaryTextDisplay}
                                          isDisplayMode={true}/></>
                                    :
                              <SentenceList textList={props.textList}
                                    removeLastSentence={removeLastText}
                                    isDisplayMode={isGettingChoices}/>;
;

  return (
    <>
          <Card.Text>
              <h2><Separator/> {props.stepTitle}</h2>
          </Card.Text>
          <Card.Text>
                  {displayEl}
          </Card.Text>
          <Card.Text>
              {interfaceText["choose_sugg_text"](props.dataNameSingular)}
          </Card.Text>
          <Card.Text>
            {props.textList.length < MAX_TEXTS ? inputEl : ""}
          </Card.Text>
  </>
  );
}

export default SentenceOrTitleInput;
