import Card from 'react-bootstrap/Card';

import { TypeInput } from './dataChoiceInput';
import SelectedBadges from './selectedBadges';
import { Separator } from '../story_review/storyReview';

import {MAX_TAGS} from '../../utils/maxTexts';
import interfaceTextMap from '../../utils/interfaceText';

function TagInput(props) {

  const langCode = props.langCode;

  const interfaceText = interfaceTextMap[langCode]["tag_screen"];

  const MAX_TAG_CHARS = 20;

  const typeDisabled = props.textList.length >= MAX_TAGS;

  function removeFromTags(removeTag){
    const index = props.textList.indexOf(removeTag);
    if (index !== -1){
      var newTags = [...props.textList];
      newTags.splice(index, 1);
      props.setTagList(newTags);
    }
  }

  function addToTags(addTag){
      const newTags = [...props.textList, addTag];
      props.setTagList(newTags);
  }

  const tagInputName = interfaceText["tag_input_name"];
  const stepDescriptionList = interfaceText["step_descriptions"];
  const stepDescriptionEls = stepDescriptionList.map((sd) => <>{sd}<br/></>);

  return (
    <>
          <Card.Title>
              <h2><Separator/> {interfaceText["step_title"]}</h2>
          </Card.Title>
          <Card.Text>
              {stepDescriptionEls}
          </Card.Text>
          <Card.Text>
            <SelectedBadges
              textList={props.textList}
              removeText={removeFromTags}/>
          </Card.Text>
          <Card.Text>
              <TypeInput langCode={langCode} dataType={tagInputName} submitTyped={addToTags} maxChars={MAX_TAG_CHARS} disabled={typeDisabled} isTagInput={true}/>
          </Card.Text>
  </>
  );
}

export default TagInput;
